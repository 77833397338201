import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Particles from "react-particles-js"
import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa"
import SearchButton from "./searchButton"

const Layout = ({ children }) => {
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "gilbert-lumoindong-logo.png" }) {
        childImageSharp {
          fixed(
            width: 150
            height: 150
            fit: CONTAIN
            background: "rgba(0,0,0,0)"
          ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div
        className="d-flex flex-column"
        style={{ fontFamily: "Trirong, serif", minHeight: "100vh" }}
      >
        <div
          className="position-relative"
          style={{ height: "100vh", width: "100%" }}
        >
          <Particles
            className="position-absolute w-100 h-100"
            style={{ top: "0", left: "0", right: "0", bottom: "0" }}
            params={{
              particles: {
                number: {
                  value: 160,
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                },
                color: {
                  value: "#111111",
                },
                shape: {
                  type: "circle",
                  stroke: {
                    width: 0,
                    color: "#000000",
                  },
                  polygon: {
                    nb_sides: 5,
                  },
                  image: {
                    src: "img/github.svg",
                    width: 100,
                    height: 100,
                  },
                },
                opacity: {
                  value: 1,
                  random: true,
                  anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0,
                    sync: false,
                  },
                },
                size: {
                  value: 3,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 4,
                    size_min: 0.3,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: false,
                  distance: 150,
                  color: "#111111",
                  opacity: 0.4,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 1,
                  direction: "none",
                  random: true,
                  straight: false,
                  out_mode: "out",
                  bounce: false,
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 600,
                  },
                },
              },
              interactivity: {
                detect_on: "window",
                events: {
                  onHover: {
                    enable: true,
                    mode: "bubble",
                  },
                  onclick: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 250,
                    size: 0,
                    duration: 2,
                    opacity: 0,
                    speed: 3,
                  },
                  repulse: {
                    distance: 400,
                    duration: 0.4,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
            }}
          />
          <div
            className="position-absolute"
            style={{ top: "0", left: "0", right: "0", bottom: "0" }}
          >
            <div className="d-flex justify-content-center">
              <Link className="text-center" to="/">
                <Img fixed={logo.childImageSharp.fixed} />
              </Link>
            </div>
            <ul className="nav justify-content-center">
              <li className="nav-item">
                <Link className="nav-link text-dark" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-dark" to="/60-seconds">
                  #60Seconds
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-dark" to="/vlog-kamu-hebat">
                  Vlog #KamuHebat
                </Link>
              </li>
            </ul>
            <div className="my-4" />
            {children}
            <div className="flex-grow-1" />
            <div className="my-5" />
            <div className="text-center">
              <p className="m-0">Follow Ps.Gilbert Lumoindong</p>
              <div className="my-1" />
              <div>
                <a
                  className="text-dark mx-1"
                  href="https://www.instagram.com/pastorgilbertl/"
                >
                  <FaInstagram size={"2rem"} />
                </a>
                <a
                  className="text-dark mx-1"
                  href="https://www.facebook.com/psgilbertlumoindong/"
                >
                  <FaFacebook size={"2rem"} />
                </a>
                <a
                  className="text-dark mx-1"
                  href="https://www.youtube.com/channel/UCTv936ogD_8j8RIO-S5cp4g"
                >
                  <FaYoutube size={"2rem"} />
                </a>
              </div>
            </div>
            <div className="my-4" />
            <p className="text-center">
              Ⓒ {new Date().getFullYear().toString()}. Gilbert Lumoindong
              Ministry
            </p>
            <p className="text-center small">
              Built with{" "}
              <a
                href="https://sawirstudio.com"
                className="text-dark text-decoration-none"
              >
                Sawir Studio
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="position-fixed" style={{ bottom: "2rem", right: "2rem" }}>
        <SearchButton />
      </div>
    </React.Fragment>
  )
}

export default Layout
