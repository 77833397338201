import React, { useState } from "react"
import { FaSearch } from "react-icons/fa"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Index } from "elasticlunr"

const SearchButton = () => {
  const [isClicked, setClicked] = useState(false)
  const [result, setResult] = useState([])
  const [query, setQuery] = useState("")
  function handleOnClick() {
    setClicked(!isClicked)
  }

  function handleOnChange(e) {
    const value = e.target.value
    setQuery(value)
    const index = Index.load(data.index)

    index.search(value, { expand: true }).map(({ ref }) => {
      setResult(result.concat(index.documentStore.getDoc(ref)))
    })
  }
  const { data } = useStaticQuery(graphql`
    {
      data: siteSearchIndex {
        index
      }
    }
  `)
  console.log(result)
  return (
    <React.Fragment>
      <button
        onClick={() => handleOnClick()}
        className="rounded-circle bg-primary p-3 shadow text-light"
      >
        <FaSearch size={"2rem"} />
      </button>

      {isClicked && (
        <div
          className="position-fixed bg-light"
          style={{ top: "0", left: "0", right: "0", bottom: "0" }}
        >
          <div
            className="position-absolute w-100"
            style={{ top: "0", left: "0" }}
          >
            <div className="my-5" />
            <div className="d-flex justify-content-end px-3">
              <button className="btn " onClick={() => handleOnClick()}>
                Back
              </button>
            </div>
            <div className="container">
              <div className="form-group">
                <input
                  placeholder="Cari Serial..."
                  className="form-control"
                  type="text"
                  value={query}
                  onChange={e => handleOnChange(e)}
                />
              </div>

              <ul className="p-0 overflow-auto" style={{ height: "75vh" }}>
                {result.map((page, index) => {
                  return (
                    <li
                      className="list-unstyled py-3 border-bottom overflow-auto"
                      key={`${index}`}
                      onClick={() => handleOnClick()}
                    >
                      <Link
                        to={`/60-seconds/${page.title

                          .toLowerCase()
                          .replace(/ /g, "-")
                          .replace(/---/g, "-")
                          .replace(/-&-/g, "-")
                          .replace(/,/g, "")
                          .replace(".", "")
                          .replace(/"/g, "")
                          .replace(/!/g, "")
                          .replace(/\?\?/g, "")
                          .replace("stress?", "stress")
                          .replace("bercerai?", "bercerai")
                          .replace("sorga-?", "sorga")
                          .replace(/'/g, "")
                          .replace(/:/g, "")
                          .replace(/&/g, "-and-")
                          .replace("-?", "")
                          .replace(/#/g, "")
                          .replace(/\s+/g, "-")
                          .replace(/[^\w\-]+/g, "")
                          .replace(/\-\-+/g, "-")
                          .replace(/^-+/, "")
                          .replace(/-+$/, "")}`}
                        className="text-dark text-decoration-none"
                      >
                        {page.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default SearchButton
